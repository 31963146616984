const TASK = "TASK";
const MATTER = "MATTER";

export {
  TASK,
  MATTER
}

export default {
  TASK,
  MATTER
}